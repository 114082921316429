<template>
  <div>
    <div style="box-sizing: border-box;padding-right: 24px;padding-bottom: 24px">
      <el-form label-position="left" label-width="104rem" @submit.native.prevent>
        <div style='background: #FFF;border-radius: 4px;'>
          <div class="f-24 fw-400" style="padding: 14px 24px;border-bottom: 1px solid #F2F2F2;color: #333333">基础信息</div>
          <div class="grid2 mt-24 mb-20" style="padding-left: 24px;padding-bottom: 16px">
            <el-form-item label="姓名">
              <div class="text">{{ form.clerk_name }}</div>
            </el-form-item>
            <el-form-item label="性别">
              <div class="text">{{ form.gender }}</div>
            </el-form-item>
            <el-form-item label="身份证号">
              <div class="text">{{ form.id_card }}</div>
            </el-form-item>
            <el-form-item label="出生日期">
              <div class="text">{{ form.birthday }}</div>
            </el-form-item>
            <el-form-item label="民族">
              <div class="text">{{ form.nation_text }}</div>
            </el-form-item>
            <el-form-item label="宗教信仰">
              <div class="text">{{ form.religion }}</div>
            </el-form-item>
            <el-form-item label="最高学历">
              <div class="text">{{ form.education }}</div>
            </el-form-item>
            <el-form-item label="毕业院校">
              <div class="text">{{ form.graduated_from }}</div>
            </el-form-item>
            <el-form-item label="专业">
              <div class="text">{{ form.major }}</div>
            </el-form-item>
          </div>
        </div>

        <div style='background: #FFF;border-radius: 4px;'>
          <div class="f-24 fw-400" style="padding: 14px 24px;border-bottom: 1px solid #F2F2F2;color: #333333">入职信息</div>
          <div class="grid2 mt-24" style="padding-left: 24px;padding-bottom: 16px">
            <el-form-item label="教学科目">
              <div class="text">{{ form.fist_subject_text }}/{{ form.subject_text }}</div>
            </el-form-item>
            <el-form-item label="入职时间">
              <div class="text">{{ form.entry_time }}</div>
            </el-form-item>
            <el-form-item label="联系电话">
              <div class="text">{{ form.mobile }}</div>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <el-form label-position="top" label-width="104rem" style="margin-top: 24px;" @submit.native.prevent>
        <div style='background: #FFF;border-radius: 4px;padding-bottom: 24px'>
          <div class="f-24 fw-400" style="padding: 14px 24px;border-bottom: 1px solid #F2F2F2;color: #333333">个人简介</div>
          <div class="mt-24" style="padding-left: 24px;padding-right:532px">
            <el-form-item label="优势标签">
              <el-tag v-for="i in form.tags" closable style="margin-right: 8rem">{{ i }}</el-tag>
              <!--              <el-input v-if="inputVisible" ref="saveTagInput" v-model="inputValue" class="input-new-tag" placeholder="请输入标签" size="small" @keyup.enter.native="handleInputConfirm"/>-->
              <!--              <el-button v-else class="button-new-tag" size="small" @click="showInput">+</el-button>-->
              <div style="font-weight: 400;font-size: 12px;color: #AEAEAE;margin-top: -8rem;">按回车键保存</div>
            </el-form-item>
            <el-form-item label="个人简介">
              <span style="border-radius: 4px 4px 4px 4px;border: 1px solid #DBDDE4;padding: 14px;line-height:18px;display: inline-block">
                {{ form.summary }}
              </span>
            </el-form-item>
            <el-form-item label="个人头像">
              <el-image :src="form.avator_text" style="width: 92px; height: 92px;border-radius: 4px"></el-image>
            </el-form-item>
            <el-form-item label="个人照片">
              <el-image :src="form.photo_text" style="width: 150px; height: 96px;border-radius: 4px"></el-image>
            </el-form-item>
            <el-form-item label="审核状态">
              <el-button :type="form.checked == 2 ? 'danger': ''" size="small">{{ form.checked == 0 ? '待审核' : form.checked == 1 ? '审核通过' : form.checked == 2 ? '审核不通过' : '' }}</el-button>
            </el-form-item>
            <el-form-item v-if="form.checked == 2" label="审核原因">
              <div class="text_">{{ form.failed_reason }}</div>
            </el-form-item>
          </div>

          <el-form-item v-if="form.checked == 0" style="margin-left: 24px;margin-top: 34px">
            <el-button type="primary" @click="primary">通过</el-button>
            <el-button plain type="danger" @click="danger">驳回</el-button>
          </el-form-item>

          <el-form-item v-else style="margin-left: 24px;margin-top: 34px">
            <el-button @click="$router.back()">返回</el-button>
          </el-form-item>
        </div>
      </el-form>

    </div>

    <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="MailboxDialog" title="驳回原因" width="30%">
      <div style="color: #222222">请说明驳回原因</div>
      <el-input v-model="inputValue" :autosize="{ minRows: 4, maxRows: 8}" maxlength="300" placeholder="请输入" show-word-limit style="margin-top: 14px;margin-bottom: 40px" type="textarea"></el-input>
      <div style="display: flex;justify-content: center">
        <el-button style="margin-right: 32px" type="primary" @click="onMailboxSave">保存</el-button>
        <el-button @click="MailboxDialog = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  _config: {"route": {"path": "details", "meta": {"title": "查看", "style": 0}}},
  data() {
    return {
      form: {
        name: '',
        url: '',
      },
      inputValue: '',
      options: [],
      inputVisible: false,
      MailboxDialog: false,
    }
  },
  methods: {
    danger() {
      this.MailboxDialog = true
    },
    onMailboxSave() {
      this.$_axios2.post('api/teacher/account/checked', {id: this.$route.query.id, status: 2, failed_reason: this.inputValue}).then(() => {
        this.MailboxDialog = false
        this.$_axios2.get('api/teacher/account/details', {params: {id: this.$route.query.id}}).then(res => {
          this.form = res.data.data
        })
      })
    },
    primary() {
      this.$_axios2.get('api/teacher/account/checked-all', {params: {ids: this.$route.query.id}}).then(() => {
        this.$_axios2.get('api/teacher/account/details', {params: {id: this.$route.query.id}}).then(res => {
          this.form = res.data.data
        })
      })
    }

  },
  created() {
    this.$_axios2.get('api/teacher/account/details', {params: {id: this.$route.query.id}}).then(res => {
      this.form = res.data.data
    })
  }
}
</script>
<style lang="scss" scoped>
.grid2 {
  display: grid;
  grid-template-columns: 344rem 344rem 1fr;
  grid-column-gap: 24px;
}

.text {
  font-weight: 500;
  font-size: 16rem;
  color: #1E3AA2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_ {
  font-weight: 500;
  font-size: 16rem;
  color: #1E3AA2;
}

</style>
